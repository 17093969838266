import React from 'react'
import { graphql } from 'gatsby';
import { Translation } from 'react-i18next';
import { withI18next, Link } from '@wapps/gatsby-plugin-i18next';
import Layout from 'layouts/index'

const ErrorPage = props => (<Translation>{t => (
    <Layout  name={'errorPage'} title={t('pageNotFoundTitle')} {...props}>
        <div className="content-block">
            <div className="content-block-content">
                <h1>{t('pageNotFoundTitle')}</h1>
                <p>{t('pageNotFoundText')}</p>
                <div className="actions">
                    <Link to={"/"} className="btn btn-accept">
                        {t('home')}
                    </Link>
                </div>
            </div>
        </div>
    </Layout>
)}</Translation>);

export const query = graphql`
  query($lng: String!){
    locales: allLocale(filter: { lng: { eq:$lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }
    site {
      siteMetadata {
        siteTitle
        activeEnv
        analytics
      }
    }
  }
`;

export default withI18next()(ErrorPage);
